@import "colors.scss";
@import "size_variables.scss";
.header-container{
    background-color: $header-background-color;
    z-index: 100000;
    &.hide { display: none !important; }
    .header-content-container {
      width: 100%;
      max-width: $app-container-max-width;
      margin:0 auto;
      .header {
        height: $header-height;
        background-color: $header-background-color;
        .auth-controls {
          flex-wrap: nowrap;
        }
        .subscribe-now-btn  {
          display: inline-block;
          font-weight: bold;
          white-space: nowrap;
          background-image: linear-gradient(to right, #3D64F8 0%, #3D93F8 100%);
          box-shadow: 0 6px 10px 0 rgba(61,100,248,0.20);
          border-radius: 8px;
          border: none !important;
          cursor: pointer;
          padding: 10px;
          &:hover {
            background-image: linear-gradient(to right, #3D93F8 0%, #3D93F8 100%);
          }
        }
        .user-btn {
          &.show {
            button {
              box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19) !important;
              background-color: #3E3E5D;
              text-align: center;
            }
            .btn-my-account {
              margin-bottom: 2px;
              border-radius: 3px;
            }
          }
          .user-email {
            background-color: unset;
            border: unset;
            .email-truncated {
              max-width: 200px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            &:active{
              background-color: unset;
            }
            &:hover {
              // border: 0.5px solid #6c757d;
              box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19) !important;
              background-color: #3E3E5D;
            }
          }
          .btn-sign-out {
            text-align: center;
            background-color: #3e3e5d !important;
            border-radius: 4px;
            &:hover {
              background-color: #373752 !important;
              border-radius: 4px !important;
            }
          }
        }
        &.row{
          justify-content: space-between;
          align-items:center;
          width: 100%;
        }
        &-menu {
          flex: 1;
          height: auto;
        }
        &-menu.row{
          align-items: center;
          justify-content:flex-start;
        }
        &-btn{
          flex: 1;
          color: white;
          font-family: Lato, aria;
          align-items: center;
          height: auto;
          display: flex;
          align-items: center;
          justify-self: flex-end;
          justify-content: flex-end; 
          .btn-transparent{
            font-size: 16px;
            line-height: 22px;
            background-color: unset;
            border: unset;
            padding: 5px 22px;
    
            font-weight: 100;
            color: #ffffff;
          }
          .btn-primary{
            line-height: 22px;
            background-color:$primary-color !important;
            border-color: $primary-color !important;
            padding: 4px 10px;
            border-radius: 8px;
            &:hover {
              background: $primary-color !important;
              border:none !important;
              outline: none !important;
              box-shadow: none !important;
            }
            &:focus {
              background: $primary-color !important;
              border:none !important;
              outline: none !important;
              box-shadow: none !important;
            }
            &:active {
              background-color: $primary-color !important;
              border:none !important;
              outline: none !important;
              box-shadow: none !important;
            }
          }
          .btn-limit-text {
            max-width: 170px;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-text-overflow: ellipsis;
            -moz-text-overflow: ellipsis;
          }
    
          .btn-group .dropdown-menu{
              background-color:#2a2a49 !important;
              border-radius: 6px;
              border: none;
              padding: 0;
              width: 170px;
              top: 0;
              box-shadow: 0 5px 8px 0 rgba(0, 0, 0,0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19) !important;
              .dropdown-item{
                padding: 8px 2px 8px 10px;
                &:hover{
                  background-color: #35355f;
                  border-radius: 6px;
                }
              }
            }
    
          .log-in-btn, .sign-up-btn {
            width: 133px;
            height: 45px;
            font-size: 18px;
          }
          .tut-vids-btn {
            width: 185px;
            height: 45px;
            font-size: 18px;
          }
          .log-in-btn {
            font-weight: 400;
          }
          .sign-up-btn {
            font-weight: 600;
          }
        }
        &-logo{
          display: inline-block;
          height: 42px;
          max-width: 110px;
          margin-right: 30px;
          .odin-pokercode-image {
            height: 62px;
          }
    
          .odin-image {
            height: 45px;
          }
        }
        &-nav-menu{
          flex: 1;
          justify-content: space-evenly;
          a {
            color: white;
            text-decoration: none;
          }
          .my-nav-link {
            font-size: 16px;
            color: #ffffff;
            font-weight: 100;
            margin-left: 2rem;
          }
        }
      }
    }

    //Section mobile css//
    .hamburger-icon-label {
      display: none;
      margin-bottom: 0;
    }

    .hamburger-chk {
      display: none;
    }

    .cog-icon-label {
      font-size: 25px;
      margin-top: auto;
      margin-bottom: auto;
      padding-right: 15px;
      &:active {
        transform: scale(0.9);
        transition: .1s;
      }
    }
    
    .header-mobile-container {
      &.show{
        display: flex;
      }
      display: none;
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: #161621;
      margin-top: $header-height;
      // z-index: 99999;
      align-items: center;
      justify-content: center;
      animation: fadeIn 0.5s, growing 0.5s;
      .header-mobile {
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: 800;
        color: white;
        .user-email {
          font-size: 16px;
          color: #878787;
          margin-bottom: 0;
        }
        .header-nav-menu-mobile {  
          display: flex;
          flex-direction: column;
          flex: 1;
          justify-content: space-evenly;
          align-items: center;
          a {
            color: white;
            text-decoration: none;
          }
          .my-nav-link, .btn-sign-out {
            width: 100%;
            display: flex;
            justify-content: center;
            color: #ffffff;
          }
        }
      }
    }
    .header-button, .header-button:disabled, .header-button:hover {
      background-color: unset !important;
      border: unset;
      height: 24px;
      padding: 0px;
    }
    .text-effect {
      letter-spacing: 1px;
      // --stroke-color: #5EE4F6;
      // --stroke-width: 1px;
      color: #ffffff;
      font-weight: bold;
      &:hover {
        font-weight: 100;
        filter: drop-shadow(0px 0px 20px #DDFFFF);
        color: #ffffff;
        --stroke-color: #5EE4F6;
        --stroke-width: 1px;
        text-shadow: calc(var(--stroke-width) * 1) calc(var(--stroke-width) * 0) 0
          var(--stroke-color),
        calc(var(--stroke-width) * 0.9239) calc(var(--stroke-width) * 0.3827) 0
          var(--stroke-color),
        calc(var(--stroke-width) * 0.7071) calc(var(--stroke-width) * 0.7071) 0
          var(--stroke-color),
        calc(var(--stroke-width) * 0.3827) calc(var(--stroke-width) * 0.9239) 0
          var(--stroke-color),
        calc(var(--stroke-width) * 0) calc(var(--stroke-width) * 1) 0
          var(--stroke-color),
        calc(var(--stroke-width) * -0.3827) calc(var(--stroke-width) * 0.9239) 0
          var(--stroke-color),
        calc(var(--stroke-width) * -0.7071) calc(var(--stroke-width) * 0.7071) 0
          var(--stroke-color),
        calc(var(--stroke-width) * -0.9239) calc(var(--stroke-width) * 0.3827) 0
          var(--stroke-color),
        calc(var(--stroke-width) * -1) calc(var(--stroke-width) * 0) 0
          var(--stroke-color),
        calc(var(--stroke-width) * -0.9239) calc(var(--stroke-width) * -0.3827) 0
          var(--stroke-color),
        calc(var(--stroke-width) * -0.7071) calc(var(--stroke-width) * -0.7071) 0
          var(--stroke-color),
        calc(var(--stroke-width) * -0.3827) calc(var(--stroke-width) * -0.9239) 0
          var(--stroke-color),
        calc(var(--stroke-width) * 0) calc(var(--stroke-width) * -1) 0
          var(--stroke-color),
        calc(var(--stroke-width) * 0.3827) calc(var(--stroke-width) * -0.9239) 0
          var(--stroke-color),
        calc(var(--stroke-width) * 0.7071) calc(var(--stroke-width) * -0.7071) 0
          var(--stroke-color),
        calc(var(--stroke-width) * 0.9239) calc(var(--stroke-width) * -0.3827) 0
          var(--stroke-color),
          0px 0px 8px #537EFF;
      }
    }
    .blue-glowy-text {
      font-weight: 100;
      filter: drop-shadow(0px 0px 20px #DDFFFF);
      color: #ffffff;
      --stroke-color: #5EE4F6;
      --stroke-width: 1px;
      text-shadow: calc(var(--stroke-width) * 1) calc(var(--stroke-width) * 0) 0
        var(--stroke-color),
      calc(var(--stroke-width) * 0.9239) calc(var(--stroke-width) * 0.3827) 0
        var(--stroke-color),
      calc(var(--stroke-width) * 0.7071) calc(var(--stroke-width) * 0.7071) 0
        var(--stroke-color),
      calc(var(--stroke-width) * 0.3827) calc(var(--stroke-width) * 0.9239) 0
        var(--stroke-color),
      calc(var(--stroke-width) * 0) calc(var(--stroke-width) * 1) 0
        var(--stroke-color),
      calc(var(--stroke-width) * -0.3827) calc(var(--stroke-width) * 0.9239) 0
        var(--stroke-color),
      calc(var(--stroke-width) * -0.7071) calc(var(--stroke-width) * 0.7071) 0
        var(--stroke-color),
      calc(var(--stroke-width) * -0.9239) calc(var(--stroke-width) * 0.3827) 0
        var(--stroke-color),
      calc(var(--stroke-width) * -1) calc(var(--stroke-width) * 0) 0
        var(--stroke-color),
      calc(var(--stroke-width) * -0.9239) calc(var(--stroke-width) * -0.3827) 0
        var(--stroke-color),
      calc(var(--stroke-width) * -0.7071) calc(var(--stroke-width) * -0.7071) 0
        var(--stroke-color),
      calc(var(--stroke-width) * -0.3827) calc(var(--stroke-width) * -0.9239) 0
        var(--stroke-color),
      calc(var(--stroke-width) * 0) calc(var(--stroke-width) * -1) 0
        var(--stroke-color),
      calc(var(--stroke-width) * 0.3827) calc(var(--stroke-width) * -0.9239) 0
        var(--stroke-color),
      calc(var(--stroke-width) * 0.7071) calc(var(--stroke-width) * -0.7071) 0
        var(--stroke-color),
      calc(var(--stroke-width) * 0.9239) calc(var(--stroke-width) * -0.3827) 0
        var(--stroke-color),
        0px 0px 8px #537EFF;
    }
  .cog-icon-label {
    display: none;
  }
}

// Section for Responsive
@media (max-width: 1660px) {
  .header-container{
    .padding-content {
      padding-left: 70px !important;
      padding-right: 70px !important;
    }
  }
}

@media (max-width: 1440px) {
  .header-container{
    .padding-content {
      padding-left: 60px !important;
      padding-right: 60px !important;
    }
    .header-content-container {
      .header-menu {
        flex: unset;
      }
      .header-btn {
        .btn-transparent {
          padding: 5px 8px;
        }
      }
    }
  }
}

@media (max-width: 1336px) {
  .header-container{
    .padding-content {
      padding-left: 45px !important;
      padding-right: 45px !important;
    }
  }
}

@media (max-width: 1300px) {
  .header-container{
    .padding-content {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
    .header-nav-menu {
      .my-nav-link {
        margin-left: 1.5rem !important;
      }
    }
  }
}

@media (max-width: 1024px) {
  .header-container{
    .padding-content {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
    .header-content-container {
      .header-btn {
        .tut-vids-btn {
          width: 150px;
        }
      }
      .header-nav-menu {
        .my-nav-link {
          margin-left: 1rem;
        }
      }
    }
  }
}

@media (min-width: 856px) and (max-width: 1100px) {
  .header-container{
    $font-size: 16px;
    .header {
      .header-logo{
        margin-right: 26px;
        .odin-image {
          height: 40px;
        }
      }
      .header-nav-menu {
        .my-nav-link {
          font-size: $font-size;
          letter-spacing: 0px;
        }
      }
      .header-btn {
        .user-email {
          padding: 0px;
          white-space: nowrap; 
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }  
}

@media (max-width: 856px) {
  .header-container{
    font-size: 14px;
    .header {
      .header-logo{
        margin-right: 26px;
        .odin-pokercode-image {
          height: 50px;
        }
        .odin-image {
          height: 40px;
        }
      }
      .header-nav-menu {
        .my-nav-link {
          font-size: 14px;
          letter-spacing: 0px;
        }
      }
      .header-btn {
        max-width: 300px;
        .subscribe-now-btn {
          padding: 7px 10px;
        }
        .user-email {
          padding: 0px;
          white-space: nowrap; 
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }  
}

@media (max-width: 768px) {
  .header-container{
    .padding-content {
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
    .header {
      // .header-nav-menu {
      //   display: none;
      // }
      .header-btn {
        max-width: 300px;
        .subscribe-now-btn {
          padding: 7px 10px;
        }
        .user-btn {
          display: none;
        }
        .user-email {
          white-space: nowrap; 
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .hamburger-icon-label {
      display: flex;
      width: 25px;
    }
  }
}


@media (min-width: 768px) and (max-width: 1000px) {
  .header-container{
    font-size: 14px;
    .header-logo{
      .odin-pokercode-image {
        height: 50px;
      }
      .odin-image {
        height: 42px;
      }
    }
    .header-nav-menu {
      .my-nav-link {
        font-size: 14px;
      }
    }
  }
}

@media (min-width: 1000px) {
  .header-container{
    .header-content-container {
      .header-nav-menu {
        flex: unset;
      }
    }
  }
}

@media (max-width: 500px)
{
  .header-container{
    .header-content-container {
      .header {
        &-menu{
          .header-logo{
            margin-right: 0;
          }
        }
        &-btn{
          .tut-vids-btn { width: 125px; font-size: 14px !important; height: 35px; }
          .log-in-btn, .sign-up-btn {
            width: 100px;
            height: 35px;
            font-size: 18px;
          }
          .btn-transparent{
            padding: 4px 6px;
          }
          .btn-primary{
            padding: 4px 10px 4px 8px;
          }
        }
      }
    }
  }
}


@media (max-width: 420px)
{
  .header-container{
    .header-content-container {
      .header {
        &-btn{
          .tut-vids-btn { width: 115px; font-size: 14px !important; height: 32px; }
          .log-in-btn, .sign-up-btn {
            width: 70px;
            height: 32px;
            font-size: 14px;
          }
          .btn-transparent{
            padding: 5px 2px;
          }
          .btn-primary{
            padding: 5px 2px;
          }
        }
      }
    }
  }
}

@media (max-width: 318px)
{
  .header-container{
    .header-content-container {
      .header {
        &-menu{
          .header-logo {
            // height: 34px;
            // .odin-logo {
            //   .odin-image {
            //     height: 34px;
            //   }
            // }
          }
        }
        &-btn{
          .tut-vids-btn { width: 100px; font-size: 14px !important; height: 32px; }
          .subscribe-now-btn{
            padding: 5px;
            font-size: 14px;
          }
          .log-in-btn, .sign-up-btn {
            width: 60px;
            height: 34px;
            font-size: 14px;
          }
          .btn-transparent{
            padding: 5px 4px;
          }
          .btn-primary{
            padding: 5px 4px;
          }
        }
      }
    }
  }
}

@keyframes growing {
  0%{
    top: 20%;
  }
  100%{
    top: 0;
  }
}

.header-container {
  // MOBILE PORTRAIT
  @media screen and (max-width: 428px) and (orientation: portrait) {
    &.mobile-should-hide { display: none; }
    .mobile-hide { display: none; }
    .cog-icon-label.mobile-show { display: block; }
    .header-nav-menu { display: none; }
    .header-content-container .header .auth-controls .tut-vids-btn { max-width: 100px; }
  }

  // MOBILE LANDSCAPE
  @media screen and (min-width: 667px) and (max-width: 926px) and (orientation: landscape) {
    &.mobile-should-hide { display: none; }
    &.header-main-page {
      .header-content-container { display: none; }
      .header-mobile-container .header-mobile .header-nav-menu-mobile .my-nav-link.landscape { display: flex ; }
    }
    .mobile-hide { display: none; }
    .cog-icon-label.mobile-show { display: block; }
    .header-mobile-container .header-mobile .header-nav-menu-mobile .my-nav-link.landscape { display: none ; }
    .header-content-container {
      &.padding-content { padding: 0 15px !important; }
      .desktop { display: none; }
      .header-btn {
        .header-nav-menu.landscape { flex: 0 1; }
        .subscribe-now-btn.landscape { margin-right: 30px !important; }
        .hamburger-icon-label { display: block; z-index: 99; }
      }
    }
  }

  // TABLET PORTRAIT
  @media screen and (min-width: 768px) and (max-width: 992px) and (orientation: portrait) {

  }
}
